<template>
	<v-card flat class="wr_grey_1" height="100%">
		<v-row class="full-height-wr">
			<!-- Words Column -->
			<v-col>
				<v-card outlined height="100%">
					<v-card-text class="subtitle-1 font-weight-bold black--text pb-0">
						{{ $t("app.helping_words_to_prepare_ques") }}
					</v-card-text>

					<v-card-text>
						<v-row no-gutters>
							<v-col
								sm="6"
								md="4"
								v-for="(category, index) in word_categories"
								:key="'c' + index"
								class="pa-1"
							>
								<AppButton
									:label="category.label"
									:color="category.color"
									:dark="true"
									:block="true"
									:outlined="category.value != selected_category"
									:icon="category.icon"
									:prop_class="[
										{
											'font-weight-bold': category.value == selected_category,
											'subtitle-1': true,
										},
									]"
									@click="selected_category = category.value"
								></AppButton>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-text v-if="selected_category">
						<div class="subtitle-1 font-weight-bold black--text">
							{{ $t("app.related_words") }}
						</div>
						<v-chip-group column class="mt-2">
							<v-chip
								v-for="(item, index) in $defines.WORDS[selected_category]"
								:key="'question' + index"
								label
								:color="selectedCategoryColor"
								:outlined="!isWordUsed(item)"
								@click="selectWord(item, selectedCategoryColor)"
								:class="{
									'font-weight-bold white--text': isWordUsed(item),
									'subtitle-1 px-6': true,
								}"
							>
								{{ $te(item) ? $t(item) : item }}
							</v-chip>
						</v-chip-group>
					</v-card-text>
					<!-- Mind map words -->
					<v-card-text
						class="mt-5 pb-0 subtitle-1 font-weight-bold black--text"
					>
						{{ $t("app.words_you_marked_fav") }}
					</v-card-text>
					<v-card-text>
						<v-chip-group column>
							<v-chip
								v-for="(item, index) in mindMapKeywords"
								:key="index"
								color="wr_blue_1"
								label
								:outlined="!isWordUsed(item.name)"
								@click="selectWord(item.name)"
								:class="{
									'font-weight-bold white--text': isWordUsed(item.name),
									'subtitle-1 px-6': true,
								}"
							>
								{{ item.name }}
							</v-chip>
						</v-chip-group>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- Question Column -->
			<v-col>
				<v-card outlined height="100%">
					<template v-if="question.length">
						<v-card-text>
							<div class="subtitle-1 font-weight-bold black--text">
								{{ $t("app.selected_words") }}
							</div>
							<div>
								{{ $t("app.drag_and_drop_words") }}
							</div>
						</v-card-text>

						<v-card-text class="pt-0">
							<draggable
								v-model="question"
								v-bind="dragOptions"
								@start="drag = true"
								@end="drag = false"
							>
								<transition-group
									type="transition"
									:name="!drag ? 'flip-list' : null"
								>
									<v-chip
										v-for="(item, index) in question"
										:key="'p' + index"
										:color="item.color"
										class="white--text subtitle-1 px-6 ma-1"
										label
										close
										:close-icon="$vuetify.icons.values.cancel"
										@click:close="removeWord(item.id)"
									>
										{{ $te(item.word) ? $t(item.word) : item.word }}
									</v-chip>
								</transition-group>
							</draggable>
						</v-card-text>

						<v-card-text class="mt-15">
							<div class="subtitle-1 font-weight-bold black--text">
								{{ $t("app.my_research_questionl") }}
							</div>
							<div>
								{{ $t("app.question_update_auto") }}
							</div>
						</v-card-text>

						<v-card-text
							v-if="question.length"
							class="title font-weight-bold info--text"
						>
							{{ preparedQuestion | capitalize }}
						</v-card-text>
					</template>

					<NoData
						v-else
						heading="app.no_words_yet"
						:show_action="false"
					></NoData>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { generateRandomString } from "@/utils/helpers";
import { mapState } from "vuex";
import NoData from "@/components/shared/NoData.vue";
import draggable from "vuedraggable";

export default {
	name: "QuestionCreator",

	data() {
		return {
			meta_panel: [0, 1],
			selected_category: 0,
			question: [],
			drag: false,
		};
	},

	components: {
		NoData,
		draggable,
	},

	mounted() {
		this.getMindmapKeywords();
	},

	computed: {
		...mapState({
			mindMapKeywords: (state) => state.keyword.mindmap_keywords,
		}),

		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
			};
		},

		selectedCategoryColor() {
			return this.word_categories.find(
				(item) => item.value == this.selected_category,
			).color;
		},

		preparedQuestion() {
			return (
				this.question
					.map((item) => (this.$te(item.word) ? this.$t(item.word) : item.word))
					.join(" ") + "?"
			);
		},

		word_categories() {
			return [
				{
					value: 10,
					label: "app.question_words",
					color: "pink",
					icon: "info_circle_filled",
				},
				{
					value: 20,
					label: "app.operational_words",
					color: "blue-grey",
					icon: "settings",
				},
				{
					value: 30,
					label: "app.linking_words",
					color: "purple",
					icon: "attachment",
				},
				{
					value: 40,
					label: "app.connecting_words",
					color: "light-blue",
					icon: "google_circle_extended",
				},
				{
					value: 50,
					label: "app.feeling_words",
					color: "teal",
					icon: "emoji",
				},
				{
					value: 60,
					label: "app.timing_words",
					color: "green",
					icon: "timer",
				},
				{
					value: 70,
					label: "app.place_words",
					color: "orange",
					icon: "airplane_landing",
				},
				{
					value: 80,
					label: "app.helping_words",
					color: "brown",
					icon: "clipboard_text",
				},
			];
		},
	},

	methods: {
		selectWord(word, color = "wr_blue_1") {
			this.question.push({
				id: generateRandomString(),
				word,
				color,
			});
		},

		removeWord(id) {
			let index = this.question.findIndex((item) => item.id == id);
			if (index != -1) {
				this.question.splice(index, 1);
			}
		},

		isWordUsed(word) {
			let index = this.question.findIndex((item) => item.word == word);
			return index !== -1;
		},

		async getMindmapKeywords() {
			try {
				this.$loader.start();

				await this.$store.dispatch("keyword/mindMapKeywords");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style>
.card-wr {
	height: 40vh;
	max-height: 40vh;
	overflow: auto;
}
</style>
