<template>
	<QuestionCreator></QuestionCreator>
</template>

<script>
import QuestionCreator from "@/components/shared/tools/questionCreator/Index.vue";

export default {
	name: "QuestionCreatorPage",

	components: {
		QuestionCreator,
	},
};
</script>

<style></style>
