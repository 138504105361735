var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"wr_grey_1",attrs:{"flat":"","height":"100%"}},[_c('v-row',{staticClass:"full-height-wr"},[_c('v-col',[_c('v-card',{attrs:{"outlined":"","height":"100%"}},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-bold black--text pb-0"},[_vm._v(" "+_vm._s(_vm.$t("app.helping_words_to_prepare_ques"))+" ")]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.word_categories),function(category,index){return _c('v-col',{key:'c' + index,staticClass:"pa-1",attrs:{"sm":"6","md":"4"}},[_c('AppButton',{attrs:{"label":category.label,"color":category.color,"dark":true,"block":true,"outlined":category.value != _vm.selected_category,"icon":category.icon,"prop_class":[
									{
										'font-weight-bold': category.value == _vm.selected_category,
										'subtitle-1': true,
									} ]},on:{"click":function($event){_vm.selected_category = category.value}}})],1)}),1)],1),(_vm.selected_category)?_c('v-card-text',[_c('div',{staticClass:"subtitle-1 font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.$t("app.related_words"))+" ")]),_c('v-chip-group',{staticClass:"mt-2",attrs:{"column":""}},_vm._l((_vm.$defines.WORDS[_vm.selected_category]),function(item,index){return _c('v-chip',{key:'question' + index,class:{
								'font-weight-bold white--text': _vm.isWordUsed(item),
								'subtitle-1 px-6': true,
							},attrs:{"label":"","color":_vm.selectedCategoryColor,"outlined":!_vm.isWordUsed(item)},on:{"click":function($event){return _vm.selectWord(item, _vm.selectedCategoryColor)}}},[_vm._v(" "+_vm._s(_vm.$te(item) ? _vm.$t(item) : item)+" ")])}),1)],1):_vm._e(),_c('v-card-text',{staticClass:"mt-5 pb-0 subtitle-1 font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.$t("app.words_you_marked_fav"))+" ")]),_c('v-card-text',[_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.mindMapKeywords),function(item,index){return _c('v-chip',{key:index,class:{
								'font-weight-bold white--text': _vm.isWordUsed(item.name),
								'subtitle-1 px-6': true,
							},attrs:{"color":"wr_blue_1","label":"","outlined":!_vm.isWordUsed(item.name)},on:{"click":function($event){return _vm.selectWord(item.name)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1),_c('v-col',[_c('v-card',{attrs:{"outlined":"","height":"100%"}},[(_vm.question.length)?[_c('v-card-text',[_c('div',{staticClass:"subtitle-1 font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.$t("app.selected_words"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("app.drag_and_drop_words"))+" ")])]),_c('v-card-text',{staticClass:"pt-0"},[_c('draggable',_vm._b({on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((_vm.question),function(item,index){return _c('v-chip',{key:'p' + index,staticClass:"white--text subtitle-1 px-6 ma-1",attrs:{"color":item.color,"label":"","close":"","close-icon":_vm.$vuetify.icons.values.cancel},on:{"click:close":function($event){return _vm.removeWord(item.id)}}},[_vm._v(" "+_vm._s(_vm.$te(item.word) ? _vm.$t(item.word) : item.word)+" ")])}),1)],1)],1),_c('v-card-text',{staticClass:"mt-15"},[_c('div',{staticClass:"subtitle-1 font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.$t("app.my_research_questionl"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("app.question_update_auto"))+" ")])]),(_vm.question.length)?_c('v-card-text',{staticClass:"title font-weight-bold info--text"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.preparedQuestion))+" ")]):_vm._e()]:_c('NoData',{attrs:{"heading":"app.no_words_yet","show_action":false}})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }